import { Navbar, Nav } from 'react-bootstrap';
import { FlexWrapper } from '../../theme/components';
import './style.css'
import ScoutOutLogo from '../../assets/images/scoutout-logo.png';
import LogoutIcon from '../../assets/images/logout.png';
import { useEthers } from "@usedapp/core";
import { Text } from '@chakra-ui/layout';

import config from '../../config';
declare let window: any;

const HeaderComponent = () => {
    const { account } = useEthers();

    


    return (
        <>
            <Navbar collapseOnSelect expand='md' variant='dark' className="header-container-control">
                <Navbar.Brand href="#"><img src={ScoutOutLogo} className="scoutout-logo"></img></Navbar.Brand>

                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav>
                        <Nav.Link href='https://scoutout.io/' target="_blank" className="my-auto">Home</Nav.Link>
                        <Nav.Link href='#'  target="_blank" className="my-auto">Invest</Nav.Link>
                        <Nav.Link href='https://scoutout.io/scoutout-system/'  target="_blank" className="my-auto">ScoutOut System</Nav.Link>
                        <Nav.Link href='https://scoutout.io/remote-workforce/'  target="_blank" className="my-auto">Remote workforce</Nav.Link>
                        {/* {
                            account ?
                                <Nav.Link>
                                    <FlexWrapper display="flex" directionFlex="row">
                                        <img src={LogoutIcon} className="logout-img"></img> Logout
                                    </FlexWrapper>
                                </Nav.Link>
                                : null
                        } */}

                    </Nav>

                </Navbar.Collapse>

            </Navbar>
        </>
    )
}

export default HeaderComponent;