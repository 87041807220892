import React from "react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import "@fontsource/inter";
import './style.css';
import { FlexWrapper } from "../../theme/components";
import ScoutOutToken from "../../assets/images/so-token.png";
import {  Text } from "@chakra-ui/react";
import config from "../../config";
import MTOKEN_IMG from "../../assets/images/m-token.png";
import ReactGA from 'react-ga';
import LOGOMONDAY from '../../assets/images/logo-monday.png';
import { useWindowWidth } from "@react-hook/window-size";

declare var window: any;
function DashboardComponent() {
    ReactGA.initialize('UA-210321266-2');
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false


    function clickMondayClub() {
        window.open(config.app_monday_club, '_blank');
        ReactGA.event({
            category: 'Investing',
            action: 'Click',
            label: 'Button MondayClub'
        });
    }


    return (
        <div className="height-control">
            <ChakraProvider>
                <div className="container container-control">

                    <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="column"
                        mt={isTablet ? "80px" : isMobile ? "80px" : "80px"} className="border-flex">
                        <img src={ScoutOutToken} className="img-coin" alt="invest scoutout"></img>
                      
                        <FlexWrapper display="flex" mb="30px" directionFlex="column" className="justify-content-center">

                            <Text className="text-header my-auto mx-auto">We have converted all the iScout token to <a href={config.app_monday_club} target="_blank" className="text-monday">$MONDAY </a>
                                Token <br></br>Please find your tokens  <a href={config.app_monday_club} target="_blank" className="text-monday">here</a></Text>
                            <img src={LOGOMONDAY} className="logo-monday mx-auto"></img>

                            <img src={MTOKEN_IMG} className="token-img mx-auto"/>
                            <button className="button-monday-club" onClick={clickMondayClub}>Visit Monday Club</button>
                        </FlexWrapper>

                        {/* <TotalSupply totalSupply={totalSupply} totalSale={totalSale} percentSupply={percentSupply} TotalSaleHalf={TotalSaleHalf} ></TotalSupply> */}

                        {/* <FlexWrapper display="flex" directionFlex="column" className="text-header-control">
                            <FlexWrapper display="flex" directionFlex={isTablet ? "row" : isMobile ? "column" : "row"} className="justify-content-center border-bsc-scan-control">
                                <Button className="border-bsc-scan" onClick={clickISCOUT}>iScout BSC Scan</Button>
                                <Button className="border-bsc-scan" onClick={clickPrivateToken}>Private Token Sale BSC Scan</Button>
                            </FlexWrapper>



                        </FlexWrapper> */}
                        
                    </FlexWrapper>
                </div>

            </ChakraProvider >
        </div >
    )
}

export default DashboardComponent;

function oldFlow() {

    return(
        <>
        {/* {
                            !isAccess ? <FlexWrapper display="flex" directionFlex="column" className="section-access-control">
                                <img src={ISCOUTIcon} className="modal-login-img mx-auto" alt="invest scoutout"></img>
                                <span className="modal-text-header">Input your registered email to access.</span>
                                <Input
                                    type="text"
                                    className="modal-login-input"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="E-mail Address"
                                />
                                {
                                    isError ? <span className="modal-text-error-msg">You cannot access. Please contact at
                                        <a href="mailto:invest@scoutout.io"> invest@scoutout.io</a></span> : null
                                }

                                <Button
                                    className="modal-login-button-submit"
                                    onClick={() => logInWithEmailAndPassword(email)}
                                >Access</Button>

                                <span className="modal-text-desc">If cannot access or would like to invest in Monday Club please contact
                                    <a href="mailto:invest@scoutout.io"> invest@scoutout.io</a></span>
                              
                            </FlexWrapper> :



                                <FlexWrapper directionFlex="column">


                                    <ConnectWallet account={account} showModal={showModal} ToggleDesktop={ToggleDesktop} connectWallet={handleConnectWallet}
                                        isNetworking={isNetworking} handleChangeNetwork={handleChangeNetwork} handleClickWallet={handleSwitchWallet}>
                                    </ConnectWallet>


                                    <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : !window.ethereum ? "flex" : "none"} mt="50px" directionFlex="row" className="steps-flex-control">
                                        <Button className="button-connect-wallet"
                                            onClick={() => ToggleDesktop()}
                                        >Start Investing</Button>
                                    </FlexWrapper>


                                    <FlexWrapper display={isTablet ? "none" : isMobile ? "none" : !window.ethereum ? "none" : "flex"} directionFlex="column">
                                        <Divider className="divider-step"></Divider>
                                        <ChooseToken isNetworking={isNetworking}
                                            selectedOption={selectedOption} handleInputChange={handleInputChange} currency={currency} account={account}></ChooseToken>


                                        <Divider className="divider-step"></Divider>
                                        <FlexWrapper directionFlex="row" className={isCurrency ? "enable-step-3" : "disable-step-3"}>
                                            <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="column" className="steps-flex-control">
                                                <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="row">
                                                    <Text className="number-header">Step 3.</Text>
                                                    <Text className="text-step-header">Input amount</Text>
                                                    {Number(query) >= minAmount && Number(query) <= maxAmount && Number(query) > 0 ?
                                                        <img src={CheckedImg} className="img-check" alt="invest scoutout"></img> :
                                                        null
                                                    }
                                                </FlexWrapper>
                                                {

                                                    isCurrency ? <Text

                                                        className={
                                                            Number(query) < minAmount && Number(query) > 0 ? "text-step-desc text-min-amount-red" :
                                                                Number(query) > maxAmount ? "text-step-desc text-min-amount-red" :
                                                                    "text-step-desc"
                                                        }>

                                                        Minimum is {minAmount} and Maximum is {maxAmount.toLocaleString()}</Text> : null
                                                }

                                                <FlexWrapper directionFlex="row" display="flex" >
                                                    <Input
                                                        className={
                                                            Number(query) < minAmount && Number(query) > 0 ? "input-style-1 border-amount-red"
                                                                : Number(query) >= minAmount && Number(query) <= maxAmount ? "input-style-1 border-amount-green"
                                                                    : query.length == 0 ? "input-style-1 border-amount-grey"
                                                                        : Number(query) > maxAmount ? "input-style-1 border-amount-red"
                                                                            : "input-style-1 border-amount-grey"
                                                        }
                                                        type="number" id="amount"
                                                        onWheel={event => event.currentTarget.blur()}
                                                        placeholder="0.00"
                                                        value={query}
                                                        onChange={event => setQuery(event.target.value)}
                                                        isDisabled={isCurrency ? false : true}
                                                    >
                                                    </Input>


                                                </FlexWrapper>



                                            </FlexWrapper>
                                        </FlexWrapper>

                                     

                                        {
                                            !isNative ?
                                                <div>
                                                    <Divider className="divider-step"></Divider>
                                                    <FlexWrapper directionFlex="row" className={isEnable4 ? "enable-step-4" : "disable-step-4"}>
                                                        <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="column" className="steps-flex-control">
                                                            <GridWrapper display="grid" columnTemplate="60% 40%" className="grid-allowance-control">
                                                                <FlexWrapper display="flex" directionFlex="column">
                                                                    <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="row" mt="auto" mb="auto">
                                                                        <Text className="number-header">Step 4.</Text>
                                                                        <Text className="text-step-header">Check Allowance</Text>
                                                                        {
                                                                            resultApprove === "OK" || resultApprove === "ALLOW" ?
                                                                                <img src={CheckedImg} className="img-check" alt="invest scoutout"></img> : null

                                                                        }

                                                                    </FlexWrapper>
                                                                    {
                                                                        resultApprove === "Failed" && !isAprroving && !isCheckAllowance ? <Text className="text-step-desc text-min-amount-red" >
                                                                            Approval rejected
                                                                        </Text>
                                                                            : null

                                                                    }

                                                                </FlexWrapper>

                                                                {isCheckAllowance ?
                                                                    <FlexWrapper display="flex" directionFlex="column" className="border-process-allowance">

                                                                        <div className="spinner-in-border-control">
                                                                            <Spinner
                                                                                className="loading-allowance"
                                                                                thickness="2px"
                                                                                speed="0.65s"
                                                                                emptyColor="gray.200"
                                                                                color="blue.500"
                                                                                size="xs"
                                                                            />
                                                                            <Text className="text-check-allowance">checking</Text>
                                                                        </div>
                                                                    </FlexWrapper>
                                                                    : null
                                                                }
                                                                {isAprroving ?
                                                                    <FlexWrapper display="flex" directionFlex="column" className="border-process-allowance">

                                                                        <div className="spinner-in-border-control">
                                                                            <Spinner
                                                                                className="loading-allowance"
                                                                                thickness="2px"
                                                                                speed="0.65s"
                                                                                emptyColor="gray.200"
                                                                                color="blue.500"
                                                                                size="xs"
                                                                            />
                                                                            <Text className="text-check-allowance">Approving</Text>
                                                                        </div>
                                                                    </FlexWrapper>
                                                                    : null
                                                                }
                                                                {isNeedApprove && !isAprroving && !isCheckAllowance
                                                                    ? <Button className="button-need-approve" onClick={handleApprove}>Approve</Button>
                                                                    : null
                                                                }

                                                            </GridWrapper>
                                                        </FlexWrapper>
                                                    </FlexWrapper>
                                                </div>
                                                : null
                                        }

                                        <Divider className="divider-step"></Divider>
                                        <FlexWrapper directionFlex="row" className={isShowInvestButton ? "enable-step-5" : "disable-step-5"}>
                                            <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="column" className="steps-flex-control">
                                                <FlexWrapper display={isTablet ? "flex" : isMobile ? "flex" : "flex"} directionFlex="row">
                                                    <Text className="number-header">
                                                        {

                                                            isNative ? "Step 4." : "Step 5."
                                                        }
                                                    </Text>
                                                    <Text className="text-step-header">Confirm receive amount</Text>
                                                    {
                                                        isShowInvestButton ? <img src={CheckedImg} className="img-check" alt="invest scoutout"></img>
                                                            : null
                                                    }
                                                </FlexWrapper>
                                                <GridWrapper columnTemplate={isTablet ? "50% 50%" : isMobile ? "50% 50%" : "50% 50%"}

                                                    className="confirm-style border-amount-grey"
                                                >
                                                    <FlexWrapper display="flex" directionFlex="row">
                                                        <img src={ISCOUTIcon} className="img-iscout-token" alt="invest scoutout"></img>
                                                        <Text className="text-iscout-style">iScout</Text>
                                                    </FlexWrapper>
                                                    <Text className="text-confirm-style">{receiveAmount}</Text>
                                                </GridWrapper>
                                            </FlexWrapper>
                                        </FlexWrapper>
                                        <FlexWrapper directionFlex="row" className={isShowInvestButton ? "enable-step-6" : "disable-step-6"}>
                                            {isInvesting ?

                                                <FlexWrapper display="flex" directionFlex="column"
                                                    className="border-check-investing">
                                                    <Spinner
                                                        className="loading-allowance"
                                                        thickness="2px"
                                                        speed="0.65s"
                                                        emptyColor="gray.200"
                                                        color="blue.500"
                                                        size="md"
                                                    />
                                                    <Text className="text-check-allowance">Investing</Text>
                                                </FlexWrapper> : <div></div>


                                            }


                                            {
                                                !isInvesting ?
                                                    < Button className={isShowInvestButton ? "button-invest" : "button-invest-disable"}
                                                        onClick={
                                                            !isNative ?
                                                                handleTransfer
                                                                : handleInvestNative} isDisabled={!isShowInvestButton}>Invest</Button>
                                                    : null

                                            }

                                        </FlexWrapper>
                                    </FlexWrapper>


                                    <Text className="text-desc-header" mt="30px">
                                        The total token on offer is at {totalSale} tokens with the total investment hard cap of  ${config.CONTENT.HARDCAP} USD.<br></br><br></br>
                                        <b>$iScout</b> is an investment token only. And once our governance token, <span className="text-monday">$MONDAY</span>,
                                        launches launch on an exchange, you will be required to swap your $iScout for our <span className="text-monday">$MONDAY </span>
                                        governance token. Your exchanged governance tokens will be locked for {config.CONTENT.LOCKDAY} days from the
                                        launch on an exchange with <a href="https://mondayclub.gitbook.io/monday-club/the-formal-stuff/token-distribution" target="_blank">linear vesting.</a>
                                    </Text>
                                    <button className="button-monday-club" onClick={clickMondayClub}>Visit Monday Club</button>
                                    <Text className="text-white-contact">To contact our team for any enquiry please email us

                                        <a href="mailto:invest@scoutout.io" className="text-email-contact">invest@scoutout.io</a></Text>
                                </FlexWrapper>
                        } */}
        </>
    )
}