const dev = {
    monday_club: "https://monday-club-test.web.app/?utm_source=INVEST_SCOUTOUT&utm_medium=INVEST",
    app_monday_club: "https://app.mondayclub.io/portfolio?utm_source=INVEST_SCOUTOUT&utm_medium=INVEST",
   
  
   
};

const prod = {
    monday_club: "https://mondayclub.io?utm_source=INVEST_SCOUTOUT&utm_medium=INVEST",
    app_monday_club: "https://app.mondayclub.io/portfolio?utm_source=INVEST_SCOUTOUT&utm_medium=INVEST",
    
};

const config = process.env.NODE_ENV === 'production'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};