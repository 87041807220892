
import './App.css';
import { Route, Switch } from 'react-router-dom';
import DashboardComponent from './components/Dashboard';
import HeaderComponent from './components/Header';
import ReactGA from 'react-ga';
function App() {

  ReactGA.initialize('UA-210321266-2');
  return (
    <div className="bg-control">
        <HeaderComponent />
        <Switch>
          <Route exact strict path="/" component={DashboardComponent} />
          <Route path="/farm" component={DashboardComponent} />
        </Switch>
    </div>

  );
}
export default App;
